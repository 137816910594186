import { DISCORD_API_HOST } from '~/shared/api'
import { useState } from 'react'
import Cookies from 'js-cookie'

export default function DiscordLoginButton() {
	const [isHovered, setIsHovered] = useState(false)

	return (
		<button
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className='flex h-[88px] w-full items-center justify-between gap-[16px] self-stretch rounded-[44px] bg-[#121212] pl-[16px] pr-[24px] text-start transition-all hover:bg-[#5865F2]'
			onClick={() => {
				const referral = Cookies.get('referral')
				const subId = Cookies.get('subId')
				const provider = 'discord'

				let authUrl = `${DISCORD_API_HOST}/oauth2/authorization/${provider}`
				let queryParams = new URLSearchParams()

				if (referral) {
					queryParams.append('ref_code', referral)
				}

				if (subId) {
					queryParams.append('keitaro_sub_id', subId)
				}

				const queryString = queryParams.toString()
				if (queryString) {
					authUrl += `?${queryString}`
				}
				window.location.href = authUrl
			}}
		>
			<div className='flex items-center justify-center gap-[16px]'>
				<div className='flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#5865F2]'>
					<img
						className='h-[20px] w-[26px]'
						src='/icons/discordLogo.svg'
						alt='discord logo'
					/>
				</div>
				<div className='flex flex-col'>
					<p className='text-[12px] text-[#FFFFFF80]'>login with</p>
					<h5 className='text-[16px] font-semibold'>Discord</h5>
				</div>
			</div>
			{isHovered ? (
				<h5 className='text-[14px] text-[#FFFFFF]'>Continue</h5>
			) : (
				<img
					src='/icons/keyIcon.svg'
					alt='key icon'
					className='h-[23.95px] w-[12.5px]'
				/>
			)}
		</button>
	)
}
