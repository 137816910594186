import { API_HOST } from '~/shared/api'
import { useState } from 'react'
import Cookies from 'js-cookie'

export function GoogleLoginButton() {
	const handleClick = () => {
		const referral = Cookies.get('referral')
		const subId = Cookies.get('subId')
		const provider = 'google'

		let authUrl = `${API_HOST}/oauth2/authorization/${provider}`
		let queryParams = new URLSearchParams()

		if (referral) {
			queryParams.append('ref_code', referral)
		}

		if (subId) {
			queryParams.append('keitaro_sub_id', subId)
		}

		const queryString = queryParams.toString()
		if (queryString) {
			authUrl += `?${queryString}`
		}
		window.location.href = authUrl
	}
	const [isHovered, setIsHovered] = useState(false)

	return (
		<button
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			className='flex h-[88px] w-full items-center justify-between gap-[16px] self-stretch rounded-[44px] bg-[#121212] pl-[16px] pr-[24px] text-start transition-all hover:bg-[#FFFFFF]'
			onClick={handleClick}
		>
			<div className='flex items-center justify-center gap-[16px]'>
				<div className='flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#FFFFFF]'>
					<img
						className='h-[20px] w-[26px]'
						src='/icons/gglLogo.svg'
						alt='google logo'
					/>
				</div>
				<div className='flex flex-col'>
					<p
						className={`text-[12px] ${
							isHovered ? 'text-[#20202080]' : 'text-[#FFFFFF80]'
						}`}
					>
						login with
					</p>
					<h5
						className={`text-[16px] font-semibold ${
							isHovered ? 'text-[#000000]' : ''
						}`}
					>
						Google
					</h5>
				</div>
			</div>
			{isHovered ? (
				<h5 className='text-[14px] text-[#000000]'>Continue</h5>
			) : (
				<img
					src='/icons/keyIcon.svg'
					alt='key icon'
					className='h-[23.95px] w-[12.5px]'
				/>
			)}
		</button>
	)
}
